import io from 'socket.io-client';
import Vue from 'vue';

let appSocket = null;

export default {
  get authorized() {
    return !!appSocket && this.user;
  },
  user: null,
  ioState: Vue.observable({ online: false, dialState: null }),
  connect(credentials, cb) {
    this.disconnect();

    appSocket = io({
      path: process.env.NODE_ENV === 'development' ? '/ws-api' : undefined,
      query: credentials,
    });

    appSocket.on('login.success', (user) => {
      this.user = user;
      this.ioState.online = true;
      cb && cb(user);
      cb = null;
    });

    appSocket.on('device.calling', (phone, id) => {
      this.ioState.dialState = phone ? {phone, id} : null;
    });

    appSocket.on('device.calling', (phone, id) => {
      this.ioState.dialState = phone ? {phone, id} : null;
    });

    appSocket.on('disconnect', () => {
      console.log('disconnect');
      cb && cb(false);
      this.ioState.online = false;
    });

    appSocket.on('connect', () => {
      console.log('connect');
      this.ioState.online = true;
    });
  },
  disconnect() {
    if (appSocket) {
      appSocket.off();
      appSocket.disconnect();
      appSocket = null;
      this.user = null;
      this.ioState.online = false;
    }
  },
  startDial(phone, purchaseId) {
    this.emit('dialer.start', phone, purchaseId);
    this.ioState.dialState = {phone};
  },
  stopDial() {
    this.emit('dialer.stop');
    this.ioState.dialState = null;
  },
  get socket() {
    return appSocket;
  },
  emit(...params) {
    if (process.env.NODE_ENV === 'development' || localStorage.getItem('restlogs')) {
      console.log('🔝', ...params.filter(p => typeof p !== 'function'));
    }
    const f = params.length && typeof params[params.length - 1] === 'function' && params[params.length - 1];
    if (f) {
      params[params.length - 1] = (...retParams) => {
        if (process.env.NODE_ENV === 'development' || localStorage.getItem('restlogs')) {
          console.log('🆗', ...retParams);
        }
        f(...retParams);
      };
    }
    this.socket.emit(...params);
  },
  emitRest(model, modelMethod, ...params) {
    if (!this.ioState.online) {
      vueApp.$notify({
        type: 'danger',
        title: 'Ошибка',
        content: 'Нет связи с сервером - попробуйте позже',
      });
      return Promise.reject('Offline');
    }
    vueApp.$Progress.start();
    const that = this;
    return new Promise((resolve, reject) => {
      if (process.env.NODE_ENV === 'development' || localStorage.getItem('restlogs')) {
        console.log(`🔝 ${model}.${modelMethod}`, ...params);
      }
      that.socket.emit('rest', model, modelMethod, ...params, (err, result) => {
        if (err) {
          vueApp.$Progress.fail();
          vueApp.$notify({
            type: 'danger',
            title: 'Ошибка',
            content: err,
          });
          return reject(err);
        } else {
          vueApp.$Progress.finish();
        }
        resolve(result);
        if (process.env.NODE_ENV === 'development' || localStorage.getItem('restlogs')) {
          console.log(`🆗 ${model}.${modelMethod}`, result);
        }
      });
    });
  },
  // example: await rest.Purchase.find(....)
  get rest() {
    const that = this;
    const prox2 = (model) => new Proxy({}, {
      get(target, modelMethod) {
        return (...params) => that.emitRest(model, modelMethod, ...params);
      }
    });
    return new Proxy({}, {
      get(target, modelName) {
        return prox2(modelName);
      }
    });
  },
};
