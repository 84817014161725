<template lang="pug">
    .panel.panel-default
        .panel-heading База закупок
        .panel-body
            p.aux-top-buttons.hidden

                button.btn.btn-default.btn-xs(:disabled='fillStatus.active', @click='exportToBackup()') Создать бэкап
                button.btn.btn-default.btn-xs(:disabled='fillStatus.active', @click='importFromBackup()') Восстановить из бэкапа

            p.fill-controls
                label(for='fillStartPageInp') Страница/Строка/Подстраница
                input(
                id='fillStartPageInp'
                type='number',
                min=1,
                v-model='fillStatus.fillPos.page',
                :disabled='fillStatus.active',
                @keyup.enter="startFill()")
                input(
                type='number',
                min=1,
                v-model='fillStatus.fillPos.row',
                :disabled='fillStatus.active',
                @keyup.enter="startFill()")
                input(
                type='number',
                min=1,
                v-model='fillStatus.fillPos.subpage',
                :disabled='fillStatus.active',
                @keyup.enter="startFill()")

                button.btn.btn-default.btn-xs(v-if='!fillStatus.active', @click='startFill()') Старт
                button.btn.btn-default.btn-xs(v-if='fillStatus.active', @click='stopFill()') Стоп

            p(v-if='fillStatus.log', style='color: green') {{fillStatus.log}}
            p(v-if='fillStatus.error', style='color: red') {{fillStatus.error}}
</template>

<script>
  import ioClient from '../io.client';

  export default {

    created() {
    },

    mounted() {
      this.checkFill();
      ioClient.socket.on('filler.status', status => {
        this.fillStatus = status;
      });
    },

    beforeDestroy() {
      ioClient.socket && ioClient.socket.off('filler.status');
    },

    data: () => ({
      fillStatus: {
        active: false,
        log: '',
        error: '',
        fillPos: {
          page: 1,
          row: 1,
          subpage: 1,
        },
      },
    }),

    methods: {
      exportToBackup() {
      },
      importFromBackup() {
      },
      checkFill() {
        ioClient.emit('filler.check', status => {
          this.fillStatus = status;
        });
      },
      startFill() {
        ioClient.emit('filler.start', this.fillStatus.fillPos);
        this.fillStatus.active = true;
      },
      stopFill() {
        this.fillStatus.active = false;
        ioClient.emit('filler.stop');
      }
    },
  };
</script>
