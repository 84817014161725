<template lang="pug">
    mixin td_rest_edit(field)
        td.text-center()
            edit-here(
                :content=`row.${field}`,
                @save=`updateFieldContent(row, '${field}', $event)`)

    div

        include _add.user.modal.pug

        div
            p(v-if='rootMode').pull-left
                select.form-control.pull-left(v-model='selectedClientId')
                    option(value='') Все пользователи
                    optgroup(label='Клиента:')
                        option(v-for="c in clients", :value='c.id') {{c.title}}


        .pull-right(style='margin-bottom: 10px;')
            btn.btn.btn-primary(@click="openNewModal()")
                i.glyphicon.glyphicon-plus

        table.table.table-bordered
            colgroup
                col(width='1%')
                col(width='10%')
                col(width='2%')
                col(width='5%')
                col()
                col(width='5%')
                col(width='2%')
                col(width='2%', v-if="rootMode")
                col(width='8%')
                col(width='40%', v-if="rootMode")
                col(width='1%')
            thead
                tr.section-header
                    th
                    th Логин
                    th Пароль
                    th Роль
                    th ФИО
                    th Актив
                    th(v-if="rootMode") Подчинение
                    th(colspan=2) Цвет
                    th(v-if="rootMode") Предмет закупки
                    th
            tbody
                tr(v-for="row in filteredRows")
                    td
                        p(v-for='conn in (row.data ? row.data.connections : [])')
                            span(v-tooltip.hover="getConnectionDesc(conn)").text-success.glyphicon.glyphicon-signal

                    +td_rest_edit('login')

                    td
                        btn(size='xs', @click="openChangePassModal(row)") Сменить

                    td.text-center
                        span(v-if="!rootMode") {{row.role}}
                        select(v-model='row.role', @change='updateFieldContent(row, "role", row.role, true)', v-if="rootMode")
                            option(v-for="roleName in roles", :value='roleName') {{roleName}}

                    +td_rest_edit('fio')

                    td.text-center
                        input(
                            type='checkbox',
                            v-model='row.active',
                            @change='updateFieldContent(row, "active", row.active, true)',
                            v-if="!isCurrent(row)")

                    td.text-center(v-if="rootMode")
                        select(v-model='row.clientId', @change='updateFieldContent(row, "clientId", row.clientId, true)')
                            option(:value='null') ---
                            option(v-for="c in clients", :value='c.id') {{c.title}}

                    td(:style='{backgroundColor: row.color}') &nbsp;

                    +td_rest_edit('color')

                    td(v-if="rootMode")
                        edit-tags(
                            :src='row.defaultSubjects',
                            @save=`updateFieldContent(row, 'defaultSubjects', $event)`
                        )

                    td.rest-lat-col-cell

                        btn(type="danger", @click="delItem(row)", v-if="!isCurrent(row)")
                            i.glyphicon.glyphicon-trash


</template>

<script>
  import ioClient from '../io.client';
  import set from 'lodash/set';
  import get from 'lodash/get';

  const {User, Client} = ioClient.rest;

  const newUserDefaults = {
    login: '',
    newPass: '',
    role: 'manager',
    clientId: '',
    fio: '',
    active: true
  };

  export default {

    created() {
    },

    mounted() {
      this.loadClients();
      this.loadUsers();
    },

    data: () => ({
      user: ioClient.user,
      addUserModalOpened: false,
      newUser: {...newUserDefaults},
      clients: [],
      selectedClientId: '',
      rows: [],
      roles: [
        'admin',
        'manager',
        'root'
      ],
    }),

    methods: {
      created() {

      },
      async loadClients() {
        this.clients = await Client.findAll();
      },
      async loadUsers() {
        this.rows = await User.getAdminList();
      },
      async updateFieldContent(item, field, content, force = false) {
        const initial = get(item, field);
        if (!force && initial === content) return;
        try {
          const [res] = await User.update({[field]: content}, {where: {id: item.id}});
          if (res) {
            set(item, field, content);
          } else {
            set(item, field, initial);
          }
        } catch (e) {
          set(item, field, initial);
        }
      },
      openChangePassModal(item) {
        this.$prompt({
          title: 'Смена пароля',
          content: 'Новый пароль',
          inputType: 'text',
        })
          .then((str) => {
            ioClient.emit('user.newPass', item.id, str, (e) => {
              if (e) {
                this.$notify({
                  type: 'danger',
                  title: 'Ошибка',
                  content: e.message
                });
              }
            });
          })
          .catch(() => {
          })
      },
      openNewModal() {
        this.newUser.clientId = ioClient.user.clientId;
        this.addUserModalOpened = true;
      },
      async addNewUser(e) {
        e.preventDefault();
        this.newUser.color = '#' + parseInt(0xFFFFFF * Math.random()).toString(16);
        await User.create(this.newUser);
        this.addUserModalOpened = false;
        this.modalClosed();
        this.loadUsers();
      },
      modalClosed() {
        this.newUser = {...newUserDefaults};
      },
      delItem(item) {
        this.$confirm({
          title: 'Удаление',
          content: 'Вы уверены?'
        })
          .then(() => {
            return User.destroy({where: {id: item.id}});
          })
          .then(() => {
            this.loadUsers();
          })
          .catch(() => {
          })
      },
      getConnectionDesc(conn) {
        return `${this.$options.filters.dateStr(conn.date)}\n[${conn.ip}]\n[${conn.device}]`;
      },
      isCurrent(item) {
        return this.user.id === item.id;
      }
    },

    computed: {
      rootMode() {
        return this.user.role === 'root';
      },
      filteredRows() {
        return this.selectedClientId ? this.rows.filter(u => u.clientId === this.selectedClientId) : this.rows;
      }
    },
  };
</script>
