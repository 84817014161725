<template lang="pug">
    form(accept-charset='UTF-8', role='form', @submit="onSubmit", v-if='purchase.job')

        ul.list-group
            li.list-group-item {{purchase.org.title}}
            li(v-if='purchase.title').list-group-item {{purchase.title | shortDesc}}

        .form-group(v-if='callPhones.length > 0')
            btn-group()
                dropdown(v-if='callPhones.length > 1 && !ioState.dialState')
                    btn.dropdown-toggle
                        | Набрать номер &nbsp;
                        span.caret
                    template(slot='dropdown')
                        li(v-for='p in callPhones')
                            a(role='button', @click='tryDial(p.value, purchase)') {{p.label}}
                btn(type='default',
                    v-if='callPhones.length === 1 && !ioState.dialState',
                    @click='tryDial(callPhones[0].value, purchase)')
                    | Набрать {{callPhones[0].label}}
                btn(type='default', @click='stopDial', v-if='ioState.dialState') Завершить звонок

            dropdown.pull-right(v-if='recs.length > 0')
                btn.dropdown-toggle
                    | История &nbsp;
                    span.caret
                template(slot='dropdown')
                    li(v-for='rec in recs')
                        a(:href='getRecUrl(rec.id)', target='_blank') {{rec.phone}} ({{rec.createdAt | dateStr}})
        .form-group
            progress-bar(
                v-model='progress',
                v-if='ioState.dialState',
                type='info',
                striped,
                active,
                label,
                :label-text="'Идет звонок: ' + ioState.dialState.phone"
            )
        .form-group
            textarea.form-control(
                placeholder='Комментарий с результатами',
                name='comment',
                v-model='purchase.job.comment',
                :readonly='!isItemMine'
                rows='10',
                required="",
                style='resize: none;')
        input.btn.btn-primary.btn-block(type='submit', value='Сохранить', v-if='isItemMine')
</template>

<script>
  import ioClient from '../io.client';
  import get from "lodash/get";

  const {ClientPurchase, VoiceCall} = ioClient.rest;

  export default {

    props: ['purchase'],

    created() {
    },

    async mounted() {
      this.comment = get(this.purchase, 'job.comment', '');
      this.callActive = false;
      if (this.purchase.id) this.recs = await VoiceCall.findAll({where: {purchaseId: this.purchase.id}});
    },

    data: () => ({
      user: ioClient.user,
      datetime: m().format('YYYY-MM-DD HH:mm'),
      recs: [],
      callActive: false,
      progress: 100,
      ioState: ioClient.ioState,
    }),

    methods: {
      onSubmit(e) {
        e.preventDefault();
        let nextCallDate = this.purchase.job.nextCallDate;
        if (!nextCallDate || m().isAfter(nextCallDate)) {
          nextCallDate = m().format('YYYY-MM-DD');
        }
        this.$emit('save', {
          jobId: this.purchase.job.id,
          nextCallDate,
          comment: this.purchase.job.comment
        });
      },
      tryDial(phone) {
        ioClient.startDial(phone, this.purchase.id);
      },
      stopDial() {
        ioClient.stopDial();
      },
      getRecUrl(id) {
        return `/call-record/${id}?token=${encodeURIComponent(this.user.token)}`;
      }
    },

    computed: {
      isItemMine() {
        return get(this.purchase, 'job.user.id') === this.user.id;
      },
      callPhones() {
        if (get(this.purchase, 'org.phone')) {
          return (this.purchase.org.phone || '')
            .split('\n')
            .map(line => ({
              value: line.replace(/[^+0-9]/g, '').trim(),
              label: line
            }))
            .filter(({value}) => !!value);
        }
        return [];
      },
    },
  };
</script>
