import ioClient from "./io.client";

export function forAdminGuard(to, from, next) {
  if (ioClient.authorized && ["admin", "root"].includes(ioClient.user.role)) return next();
  next('/app');
}

export function forRootGuard(to, from, next) {
  if (ioClient.authorized && ioClient.user.role === 'root') return next();
  next('/app');
}
