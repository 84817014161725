<template lang="pug">
    table.table.table-bordered
        colgroup
            col(width='5%')
            col(width='5%')
            col(width='5%')
            col()
        thead
            tr.section-header
                th Дата
                th Тип
                th Пользователь
                th Прочее
        tbody
            tr(v-for="row in rows")
                td {{row.createdAt | dateStrSec}}
                td {{row.type}}
                td
                    p {{row.user ? row.user.fio : '?'}}
                    p(v-if='row.ip')
                        code.text-info.ip-addr {{row.ip}}
                td {{row.data}}

</template>

<script>
  import ioClient from '../io.client';

  const {Log} = ioClient.rest;

  export default {
    created() {
    },

    mounted() {
      this.loadStat();
    },

    data: () => ({
      rows: []
    }),

    methods: {
      async loadStat() {
        this.rows = await Log.findExt();
      },
    },
  };
</script>
