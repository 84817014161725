<template lang="pug">
    .container
        .row.vertical-offset-100
            .col-md-4.col-md-offset-4
                .panel.panel-default
                    .panel-heading
                        h3.panel-title КПП
                    .panel-body
                        form(accept-charset='UTF-8', role='form', @submit="tryLogin")
                            fieldset
                                .form-group
                                    input.form-control(
                                    placeholder='Логин',
                                    name='login',
                                    type='text',
                                    v-model='creds.login'
                                    required="")
                                .form-group
                                    input.form-control(
                                    placeholder='Пароль',
                                    name='pass',
                                    type='password',
                                    v-model='creds.pass'
                                    required="")
                                input.btn.btn-lg.btn-success.btn-block(type='submit', value='Вход')
                .text-center
                    code.text-info=`version {{creds.version}}`
</template>

<script>
  import Vue from 'vue';
  import ioClient from '../io.client';

  export default {

    created() {
    },

    mounted() {
      if (this.creds.token) {
        this.tryLogin();
      }
    },

    data: () => ({
      creds: {
        login: '',
        pass: '',
        token: localStorage.getItem('lastAuthToken') || '',
        version: appConfig.version
      }
    }),

    methods: {
      tryLogin(e) {
        e && e.preventDefault();
        this.$Progress.start();
        ioClient.connect(this.creds, user => {
          if (user) {
            localStorage.setItem('lastAuthToken', user.token);
            this.$router.replace(localStorage.getItem('redirectAfterLogin'));
          } else {
            this.$Progress.fail();
            this.creds.token = '';
            localStorage.removeItem('lastAuthToken');
            if (e) {
              this.$notify({
                type: 'danger',
                title: 'Неверный пароль',
                content: 'Попробуйте еще раз или обратитесь к администратору'
              });
            }
          }
        });
      },
    },
  };
</script>
