<template lang="pug">

    div

        include _recs.modal.pug

        p.pull-left
            select.form-control(
                style='width: 300px; display: inline-block;',
                v-model='filter.uid',
                @change='loadReports()')
                option(value='', disabled='disabled') Выберите менеджера
                option(v-for="m in managers", :value='m.id') {{m.fio}}

            span &nbsp;&nbsp;

            input.form-control(
                type='date',
                v-model="filter.period_from",
                @change="loadReports()",
                required='',
                style='width: 176px; display: inline-block;',
            )
            span &nbsp;&mdash;&nbsp;
            input.form-control(
                type='date',
                v-model="filter.period_to",
                @change="loadReports()",
                required='',
                style='width: 176px; display: inline-block;',
            )

        .clearfix

        .row

            .col-md-6

                table.table.table-bordered.report-table
                    thead
                        tr.section-header
                            th Дата
                            th Взято в работу
                            th Звонков
                            th &nbsp;
                    tbody
                        tr(v-for="(row, index) in rows")
                            td.text-center {{row.date}}
                            td.text-center {{row.jobs.length}}
                            td.text-center {{row.recs.length}}
                            td.text-center
                                btn-group(size='sm')
                                    btn(
                                        type='default',
                                        @click='openRecsModal(row)',
                                        v-tooltip.hover="'Записи'",
                                        v-if='row.recs.length > 0')
                                        span.glyphicon.glyphicon-earphone


</template>

<script>
  import ioClient from '../io.client';
  import groupBy from 'lodash/groupBy';

  const {Job, User, VoiceCall} = ioClient.rest;

  export default {

    created() {
    },

    async mounted() {
      await this.loadManagers();
    },

    data: () => ({
      user: ioClient.user,
      managers: [],
      rows: [],
      recsModalOpened: false,
      selectedDate: '',
      selectedJob: {recs: []},
      filter: {
        uid: '',
        period_from: m().subtract('7', 'day').format('YYYY-MM-DD'),
        period_to: m().format('YYYY-MM-DD'),
      },
    }),

    methods: {
      async loadManagers() {
        this.managers = await User.getUserForReport();
      },
      async loadReports() {
        this.rows = [];
        if (this.filter.uid) {
          this.rows = await Job.getUserReport(this.filter);
        }
      },
      async openRecsModal(job) {
        this.recsModalOpened = true;
        this.selectedJob = job;
      },
      recsModalClosed() {
        this.$el.querySelectorAll('audio').forEach(audioEl => audioEl.paused || audioEl.pause());
        this.selectedJob = {recs: []};
      },
      getRecUrl(id) {
        return `/call-record/${id}?token=${encodeURIComponent(this.user.token)}`;
      },
      async delRecord(voiceCallId) {
        this.$confirm({
          title: 'Удаление аудиозаписи',
          content: 'Вы уверены?'
        })
          .then(() => {
            return VoiceCall.destroy({where: {id: voiceCallId}});
          })
          .then(() => {
            this.selectedJob.recs = this.selectedJob.recs.filter(r => r.id !== voiceCallId);
          })
          .catch(() => {
          })
      }
    },
    computed: {
    }
  };
</script>
