import App from './app';
import Main from './pages/app.main';
import Orgs from './pages/app.orgs';
import Users from './pages/app.users';
import Clients from './pages/app.clients';
import Reports from './pages/app.reports';
import Proxies from './pages/app.proxies';
import LogPage from './pages/app.log';
import Settings from './pages/app.settings';
import Login from './pages/app.login';
import {forAdminGuard, forRootGuard} from './router.guards';

export default [
  {path: '/', component: Login},
  {
    path: '/app', component: App, children: [
      {path: '', component: Main, props: {owner: 'free'}},
      {path: 'all', component: Main, props: {owner: 'all'}, beforeEnter: forAdminGuard},
      {path: 'mine', component: Main, props: {owner: 'mine'}},
      {path: 'mine-closed', component: Main, props: {owner: 'mine-closed'}},
      {path: 'orgs', component: Orgs},
      {path: 'reports', component: Reports, beforeEnter: forAdminGuard},
      {path: 'users', component: Users, beforeEnter: forAdminGuard},
      {path: 'clients', component: Clients, beforeEnter: forRootGuard},
      {path: 'proxies', component: Proxies, beforeEnter: forRootGuard},
      {path: 'settings', component: Settings, beforeEnter: forRootGuard},
      {path: 'log', component: LogPage, beforeEnter: forRootGuard},
    ]
  },
];
