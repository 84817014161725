import './libs/jquery-ui';

import './sentry';
import './utils';

import './style.scss';
import './brands.scss';

import Vue from 'vue';
import VueRouter from 'vue-router';
import ioClient from "./io.client";
import get from "lodash/get";

export default (routes) => {
  const [appName] = document.location.hostname.split('.');
  document.title = `${appName.toUpperCase()} App`;

  $('body')
    .addClass(`app-${appName}`)
    .append(`<script src="/config.js"></script>`)
    .prepend('<div id="app"><router-view></router-view></div>');

  const router = new VueRouter({
    mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
    routes
  });

  router.beforeEach((to, from, next) => {
    if (!ioClient.authorized && to.path !== '/') return next('/');
    if (ioClient.authorized && to.path === '/') return next('/app');
    next();
  });

  router.afterEach((to, from) => {
    if (window.Sentry) {
      Sentry.configureScope((scope) => {
        // scope.setExtra('route', to);
        Sentry.configureScope((scope) => {
          scope.setUser({username: get(ioClient, 'user.login', 'anonymous')});
        });
      });
    }
  });

  localStorage.setItem('redirectAfterLogin', /^\/app/.test(location.pathname) ? location.pathname : '/app');

  Vue.use(VueRouter);

  Vue.config.devtools = !!localStorage.getItem('vue-devtools');
  window.vueApp = new Vue({
    router,
  }).$mount('#app');

  return window.vueApp;
};


