<template lang="pug">
    include ../_mixins

    div

        modal#modal-calls-list(v-model='newCallModalDataOpened', title='Звонок', size='lg', :footer='false')
            job-form(v-if='newCallModalData', :purchase='newCallModalData')

        div
            p.pull-right.stat-line
                span(v-if="stat.foundCount !== null") Найдено: {{stat.foundCount | num}} из {{stat.totalCount | num}}

        table.table.table-bordered.purchases-table(:class="{nomoredata: rows.length >= stat.foundCount}")
            thead.sticky-header
                tr.section-header
                    +th_sortable('Организация', 'title')
                    th Адрес
                    th ФИО
                    th Тел
                    th Факс
                    th Email
                    th Иное
                    th Числ.
                tr.search-header
                    +th_text_search('title')
                    +th_text_search('addr')
                    +th_text_search('fio')
                    +th_text_search('phone')
                    +th_text_search('fax')
                    +th_text_search('email')
                    +th_text_search('other')
                    th
            tbody
                tr(v-for="(row, rowIndex) in rows", :data-p-id="row.id")
                    td
                        span {{row.title}}
                        .org-job-users(v-if='row.jobUsers && row.jobUsers.length')
                            span(v-for='jobUser in row.jobUsers', v-tooltip.hover="jobUser.fio", :style='{backgroundColor: jobUser.color}').label {{jobUser.fio | fioLetters}}
                    +td_editable('addr', 'prewrap')
                    +td_editable('fio', 'prewrap')
                    +td_editable('phone', 'pre', 'openNewCallModal(row, $event)')
                    +td_editable('fax', 'pre')
                    +td_editable('email', 'pre')
                    +td_editable('other', 'prewrap')
                    td
                        p(v-if='row.fields && row.fields.workersCount')
                            span {{row.fields.workersCount}}

</template>

<script>
  import ioClient from '../io.client';
  import get from 'lodash/get';
  import set from 'lodash/set';
  import debounce from 'lodash/debounce';
  import JobForm from "../components/job.form";

  const {ClientOrg} = ioClient.rest;

  export default {

    components: {
      JobForm
    },

    data: () => ({
      user: ioClient.user,
      rows: [],
      stat: {
        foundCount: 0,
        totalCount: 0,
      },
      params: {
        search: {},
        sort: {
          col: 'title',
          dir: 1,
        },
        cursor: 0,
        count: 50,
      },
      loading: false,
      newCallModalData: null,
    }),

    created() {
    },

    mounted() {
      this.loadStat();
      $(window).on('scroll', debounce(() => {
        if (window.scrollY + window.innerHeight + 5 >= document.body.offsetHeight) {
          this.loading || (this.rows.length >= this.stat.foundCount) || this.loadStat(true);
        }
      }, 500));
      $('a.sort-link').click(() => $('html, body').scrollTop(0));
    },

    beforeDestroy() {
      $(window).off('scroll');
    },

    destroyed() {
    },

    methods: {
      toggleSort(field) {
        this.params = {
          ...this.params,
          sort: {
            col: field,
            dir: -1 * this.params.sort.dir,
          }
        };
        this.loadStat();
      },
      getFindParams() {
        const search = {...this.params.search};
        return {
          search,
          select: null,
          options: {
            offset: 50 * this.params.cursor || 0,
            limit: this.params.count || 50,
            order: [[this.params.sort.col || 'createdAt', this.params.sort.dir > 0 ? 'asc' : 'desc']],
          },
        };
      },
      async loadStat(more = false) {
        this.loading = true;
        if (more) {
          this.params.cursor++;
        } else {
          this.params.cursor = 0;
        }
        const p = this.getFindParams();
        const {rows, count, totalCount} = await ClientOrg.findExt({where: p.search, ...p.options});

        if (more) {
          this.rows.push(...rows);
        } else {
          this.rows = rows;
        }
        this.stat = {...this.stat, foundCount: count, totalCount};
        this.loading = false;

        // setTimeout(async () => {
        //   for (const r of rows) {
        //     await this.showSimilar(r);
        //   }
        //
        //   this.$notify({
        //     type: 'info',
        //     title: 'Поиск похожих',
        //     content: 'Завершен'
        //   });
        // }, 200);
      },
      async updateFieldContent(item, field, content) {
        const currValue = get(item, field);
        const changed = currValue !== content;
        if (!changed) return;
        if (!content) return this.revertFieldContent(item, field);

        set(item, field, content);
        await ClientOrg.update({[field]: content}, {where: {id: item.id}});
      },
      async revertFieldContent(item, field) {
        set(item, field, item.origData[field]);
        await ClientOrg.update({[field]: null}, {where: {id: item.id}});
      },
      async openNewCallModal(item, e) {
        if (e.target.tagName.toLowerCase() !== 'td') return;
        this.newCallModalData = {org: item, job: {}};
      },
      async showSimilar(item) {
        const list = await ClientOrg.findSimilarWithPhone(item.id);
        if (list.length) {
          for (const simOrg of list) {
            if (item.title.replace(/[^0-9]/g, '') !== simOrg.title.replace(/[^0-9]/g, '')) continue;
            const go = await this.$confirm({
              size: 'lg',
              title: 'Совпадение',
              html: true,
              content: `
                    <table>
                        <thead>
                            <tr><th>2020</th><th>2019</th></tr>
                        </thead>
                        <tbody>
                            <tr><td>${item.title}</td><td>${simOrg.title}</td></tr>
                            <tr><td>${item.addr}</td><td>${simOrg.addr}</td></tr>
                        </tbody>
                    </table>`,
              okText: 'Удалить дубль с обновлением адреса'
            }).catch(() => {
            });
            if (go) {
              await ClientOrg.removeDuplicate(item.id, simOrg.id);
              this.rows = this.rows.filter(row => row.id !== item.id);
              this.$notify({
                type: 'success',
                title: 'Удаление дубликата',
                content: 'Завершено'
              });
            }
          }
        } else {
          this.$notify({
            type: 'info',
            title: 'Поиск похожих',
            content: 'Не найдено'
          });
        }
      }
    },

    computed: {
      newCallModalDataOpened: {
        get() {
          return this.newCallModalData !== null;
        },
        set(v) {
          if (!v) this.newCallModalData = null;
        }
      }
    },
  };
</script>
