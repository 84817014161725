<template lang="pug">
    include ../_mixins

    div
        div
            .text-right(style='margin-bottom: 10px;')
                btn(@click="resetAll()") Сброс ошибок
                | &nbsp;
                btn.btn.btn-primary(@click="openNewModal()")
                    i.glyphicon.glyphicon-plus

        table.table.table-bordered
            colgroup
                col()
                col()
                col()
                col()
                col()
                col()
                col(width='1%')
            thead
                tr.section-header
                    th Proto
                    th Host
                    th Port
                    th Username
                    th Pass
                    th Active
                    th(title='запросов за последнюю минуту') Reqs
                    th Errors
                    th
            tbody
                tr(v-for="row in rows")
                    td {{row.proto}}
                    td {{row.host}}
                    td {{row.port}}
                    td {{row.username}}
                    td {{row.password}}
                    +td_checkbox('row.active', 'updateItem(row, "active")')
                    td {{row.successCount}}
                    td
                        p {{row.failCount || 0}}
                        p(v-if='row.lastError')
                            sup.text-muted {{row.lastError}}
                    td.rest-lat-col-cell
                        btn(type="danger", @click="delItem(row)")
                            i.glyphicon.glyphicon-trash

</template>

<script>

  import ioClient from '../io.client';

  const {ProxyServer} = ioClient.rest;

  export default {

    created() {
    },

    mounted() {
      this.loadStat();
    },

    data: () => ({
      rows: []
    }),

    methods: {
      async loadStat() {
        this.rows = await ProxyServer.findExt();
      },
      async updateItem(item, field) {
        await ProxyServer.update({[field]: item[field]}, {where: {id: item.id},});
      },
      async resetAll() {
        await ProxyServer.resetAll();
        await this.loadStat();
      },
      openNewModal() {
        this.$prompt({
          size: 'lg',
          title: 'Добавление',
          content: 'Введите новый прокси в формате: proto:host:port:username:password',
          validator: (value) => {
            if (value.split(':').length < 5) return 'Не хватает данных!';
            return null;
          }
        })
          .then(str => {
            const [proto, host, port, username, password] = str.split(':');
            return ProxyServer.create({proto, host, port, username, password, active: true});
          })
          .then(() => {
            this.loadStat();
          })
          .catch(() => {

          });
      },
      delItem(item) {
        this.$confirm({
          title: 'Удаление',
          content: 'Вы уверены?'
        })
          .then(() => {
            return ProxyServer.destroy({where: {id: item.id}});
          })
          .then(() => {
            this.loadStat();
          })
          .catch(() => {
          })
      }
    },
  };

</script>
