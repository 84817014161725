<template lang="pug">

    mixin td_rest_edit(field)
        td.text-center()
            edit-here(
                :content=`row.${field}`,
                @save=`updateFieldContent(row, '${field}', $event)`)

    div

        .pull-right(style='margin-bottom: 10px;')
            btn.btn.btn-primary(@click="openNewModal()")
                i.glyphicon.glyphicon-plus

        table.table.table-bordered
            colgroup
                col(width='20%')
                col(width='1%')
            thead
                tr.section-header
                    th Название
                    th
            tbody
                tr(v-for="row in rows")
                    +td_rest_edit('title')

                    td.rest-lat-col-cell

                        btn(type="default", @click="importData(row)")
                            i.glyphicon.glyphicon-refresh

                        span &nbsp;

                        btn(type="danger", @click="delItem(row)")
                            i.glyphicon.glyphicon-trash


</template>

<script>
  import ioClient from '../io.client';
  import set from 'lodash/set';
  import get from 'lodash/get';

  const {Client} = ioClient.rest;

  export default {

    created() {
    },

    mounted() {
      this.loadClients();
    },

    data: () => ({
      user: ioClient.user,
      rows: [],
    }),

    methods: {
      created() {

      },
      async loadClients() {
        this.rows = await Client.findAll();
      },
      async updateFieldContent(item, field, content, force = false) {
        const initial = get(item, field);
        if (!force && initial === content) return;
        try {
          const [res] = await Client.update({[field]: content}, {where: {id: item.id}});
          if (res) {
            set(item, field, content);
          } else {
            set(item, field, initial);
          }
        } catch (e) {
          set(item, field, initial);
        }
      },
      openNewModal(item) {
        this.$prompt({
          title: 'Новый клиент',
          content: 'Введите название нового клиента',
          inputType: 'text',
          validator: (value) => {
            if (value.trim().length < 1) return 'Коротковато!';
            return null;
          }
        })
          .then(str => {
            return Client.create({title: str.trim()});
          })
          .then(() => {
            this.loadClients();
          })
          .catch(() => {
          })
      },
      delItem(item) {
        this.$confirm({
          title: 'Удаление',
          content: 'Вы уверены?'
        })
          .then(() => {
            return Client.destroy({where: {id: item.id}});
          })
          .then(() => {
            this.loadClients();
          })
          .catch(() => {
          })
      },
      importData(item) {
        this.$confirm({
          title: 'Синхронизация',
          content: 'Синхронизировать клиентские закупки?'
        })
          .then(() => {
            return Client.importData(item.id);
          })
          .then(() => {
            this.$notify({
              type: 'success',
              title: 'Операция выполнена',
            });
          })
          .catch(() => {
          })
      }
    },

    computed: {},
  };
</script>
