import get from 'lodash/get';
import Vue from 'vue';

$('body')
  .append(`<script src="https://browser.sentry-cdn.com/5.0.7/bundle.min.js" crossorigin="anonymous"></script>`)
  .append(`<script src="https://browser.sentry-cdn.com/5.0.7/vue.min.js" crossorigin="anonymous"></script>`);

$(function () {
  initSentry();
});

function initSentry(attempt = 0) {
  if (get(window, 'appConfig.sentryDSN') && get(window, 'Sentry.Integrations.Vue')) {
    Sentry.init({
      dsn: appConfig.sentryDSN,
      integrations: [
        new Sentry.Integrations.Vue({
          Vue,
          attachProps: true,
        }),
      ],
    });
    console.log('Sentry logging activated');
  } else {
    console.warn('No Sentry DSN found - skipping...', attempt);
    if (attempt < 2) {
      setTimeout(initSentry, 1000 + 1000 * attempt, attempt + 1);
    }
  }
}
