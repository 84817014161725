<template lang="pug">
    div
        navbar(fixed-top, :class='{"navbar-offline": !ioState.online}')
            template(slot='collapse')
                navbar-nav

                    li(v-if='["admin", "root"].includes(user.role)')
                        router-link(to='/app/all') Все

                    li(v-if='user.clientId')
                        router-link(to='/app') Свободные

                    li(v-if='user.role==="root"').show-for-zakupru
                        router-link(to='/app/plus') Плюс список

                    li(v-if='user.clientId')
                        router-link(to='/app/mine') Мои

                    li(v-if='user.clientId')
                        router-link(to='/app/mine-closed') Мои завершенные

                    li
                        router-link(to='/app/orgs') Организации

                    li(v-if='["admin", "root"].includes(user.role) && user.clientId')
                        router-link(to='/app/reports') Отчеты

                    li(v-if='["root"].includes(user.role)')
                        router-link(to='/app/clients') Клиенты

                    li(v-if='["admin", "root"].includes(user.role)')
                        router-link(to='/app/users') Пользователи

                    li(v-if='user.role==="root"')
                        router-link(to='/app/proxies') Прокси

                    li(v-if='user.role==="root"')
                        router-link(to='/app/settings') Настройки

                    li(v-if='user.role==="root"')
                        router-link(to='/app/log') Журнал

                    li(v-if='user.role==="root"')
                        a(href='https://trello.com/b/kmQYd3f2/zakupon-ru-etc', target='_blank') Задачи

                navbar-nav(right)
                    li
                        a#qrcodeLink(href='javascript:void(0)', title='Код для Dialer')
                            i.glyphicon.glyphicon-qrcode
                        popover(target='#qrcodeLink', placement="bottom")
                            template(slot='popover')
                                qrcode-vue(:value='qrData', size='200', level='Q', render-as='svg')
                    li
                        a(href='javascript:void(0)', @click='doLogout') Выйти [{{user.login}}]

        div.app-wrapper.container-fluid
            router-view(:key='$route.path')
            vue-progress-bar()

</template>

<script>
  import ioClient from './io.client';
  import QrcodeVue from 'qrcode.vue';

  export default {

    components: {
      QrcodeVue,
    },

    data: () => ({
      user: ioClient.user,
      ioState: ioClient.ioState,
    }),

    mounted() {
      this.onlineCheckInterval = setInterval(() => {
        this.online = ioClient.online;
      }, 1000);
      ioClient.socket.on('notification', params => this.$notify(params));
    },

    beforeDestroy() {
      clearInterval(this.onlineCheckInterval);
      ioClient.socket && ioClient.socket.off('notification');
    },

    destroyed() {
    },

    methods: {
      doLogout() {
        ioClient.disconnect();
        localStorage.removeItem('lastAuthToken');
        this.$router.replace('/');
      }
    },

    computed: {
      qrData() {
        return `${document.location.origin}/apks/dialer.apk?token=${encodeURIComponent(this.user.token)}`;
      }
    }

  };
</script>

