import Vue from 'vue';
import * as uiv from 'uiv';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import VueTagsInput from '@johmun/vue-tags-input';
import VueProgressBar from 'vue-progressbar'
import linkify from 'linkifyjs/html';

Vue.use(uiv);
Vue.use(VueTagsInput);
Vue.use(VueProgressBar, {
  color: '#3399F3',
  failedColor: 'red',
  height: '2px'
});

Vue.filter('spacesToNewlines', value => (value || '').replace(/ /g, '\n'));
Vue.filter('fioLetters', value => (value || '? ?').split(' ').map(w => w.substr(0, 1)).join(''));
Vue.filter('dateStr', value => value ? m(value).format('DD.MM.YYYY HH:mm') : '-');
Vue.filter('dateStrSec', value => value ? m(value).format('DD.MM.YYYY HH:mm:ss') : '-');
Vue.filter('json', value => JSON.stringify(value, null, 2));
Vue.filter('num', value => isNaN(value) ? '' : new Intl.NumberFormat('ru-RU').format(value));
Vue.filter('shortDesc', value => value ? value.substr(0, 400) : '');
Vue.filter('fieldValEditedClass', (v, path) => {
  if (path.includes('org.')) {
    const col = path.split('.').pop();
    return get(v, 'org.origData', {})[col] ? 'overriden' : '';
  }
  return (v.origData || {})[path] ? 'overriden' : '';
});

Vue.component('edit-tags', {
  data: () => ({
    tag: '',
    tags: [],
  }),
  props: ['src', 'suggestions', 'arrayMode'],
  mounted() {
    this.srcChanged();
  },
  template: `
      <vue-tags-input
              v-model="tag"
              :tags="tags"
              @tags-changed="tagsChanged"
              @before-adding-tag="checkTag"
              :autocomplete-items="filteredItems"
              placeholder="Еще"></vue-tags-input>`,
  methods: {
    srcChanged() {
      if (this.arrayMode) {
        this.tags = (this.src || []).map(text => ({text}));
      } else {
        this.tags = (this.src || '')
          .replace(/\(|\)/g, '')
          .replace(/\|/g, ' ')
          .trim().split(' ')
          .filter(s => s)
          .map(text => ({text}));
      }
    },
    tagsChanged(newTags) {
      this.tags = newTags;
      this.emitSave();
    },
    checkTag(obj) {
      if (this.suggestions && !this.suggestions.find(s => s.text === obj.tag.text)) {
        this.$notify({
          type: 'danger',
          title: 'Ошибка',
          content: 'Неверный ввод'
        });
        return;
      }
      obj.addTag();
    },
    emitSave: debounce(function () {
      this.$emit('save',
        this.arrayMode ? this.tags.map(tag => tag.text) : this.tags.map(tag => tag.text).join(' '));
    }, 1000)
  },
  computed: {
    filteredItems() {
      return this.suggestions ? this.suggestions.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      }) : undefined;
    },
  },
  watch: {
    src(newVal, oldVal) {
      this.srcChanged();
    }
  }
});

Vue.component('edit-here', {
  data: () => ({
    active: false,
  }),
  template:
      `<p class="live-editable"
          :contenteditable="active"
          @dblclick="start"
          @blur="save"
          @keydown.enter="save"
          @keydown.esc="discard"
          v-text="content"></p>`,
  props: ['content'],
  methods: {
    start(e) {
      this.active = true;
      this.initialContent = this.content;
    },
    save(e) {
      if (!this.active) return;
      if (e && e instanceof KeyboardEvent) {
        if (e.shiftKey) return;
        e.preventDefault();
      }
      this.active = false;
      this.$emit('save', this.$el.innerText.trim());
    },
    discard() {
      this.active = false;
      this.$el.innerText = this.initialContent;
    }
  }
});

Vue.directive('visible', (el, binding) => {
  const value = binding.value;

  if (!!value) {
    el.style.visibility = 'visible';
  } else {
    el.style.visibility = 'hidden';
  }
});

Vue.directive('linkified-html', (el, binding) => {
  el.innerHTML = linkify(binding.value);
});
