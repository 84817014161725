<template lang="pug">
    include ../_mixins

    div

        modal#modal-calls-list(v-model='newCallModalDataOpened', title='Звонок', size='lg', :footer='false', @show='modalOpened')
            job-form(v-if='newCallModalData', :purchase='newCallModalData', @save='updateJob')

        div
            p(v-if='clientMode && managers.length && owner==="all"').pull-left
                select.form-control.pull-left(
                    v-model='topFilter',
                    @change='loadStat()')
                    option(value='') Все закупки
                    option(value='del') Удаленные
                    optgroup(label='В работе у менеджера:')
                        option(v-for="m in managers", :value='m.id') {{m.fio}}

            p.pull-right.stat-line
                span(v-if="stat.foundCount !== null") Найдено: {{stat.foundCount | num}} из {{stat.totalCount | num}}

        table.table.table-bordered.purchases-table(:class="{nomoredata: rows.length >= stat.foundCount}")
            thead.sticky-header
                tr.section-header
                    th
                    +th_sortable('Взята в работу', 'job.lastAssignDatetime')
                    +th_sortable('Номер', 'number')
                    +th_sortable('Код', 'code_okrb')
                    +th_sortable('Предмет', 'title')
                    th Кол-во
                    +th_sortable('Цена', 'cost')
                    +th_sortable('Организация', 'org.title')
                    +th_sortable('Звонки', 'job.nextCallDate')
                    th Адрес
                    th ФИО
                    th Тел
                    th Факс
                    th Email
                    th Проведение
                    th Примечание
                tr.search-header
                    th
                        dropdown(ref='dropdown', :not-close-elements='colChooserElements')
                            btn.dropdown-toggle(size='xs')
                                span.glyphicon.glyphicon-asterisk
                            template(slot='dropdown')
                                li(v-if="adminMode")
                                    a(role='button', @click='downloadCSV()') Экспорт в CSV
                                li(v-if="adminMode")
                                    a(role='button', @click='downloadForFaxTxt()') Экспорт в TXT (Ventafax)
                                li.divider(role='separator')
                                li
                                    a(role='button', @click='resetAllFilters()') Сбросить все фильтры
                                li.divider(role='separator')
                                li.not-close-item(v-for='col in hideableCols')
                                    a(role='button', @click='updateColVisibility(col.index)')
                                        span.glyphicon.glyphicon-ok(v-visible='!col.hidden') &nbsp;
                                        | {{col.title}}

                    //+th_date_search('job.lastAssignDatetime')
                    th
                    th
                        btn#numberFilterBtn(size='sm', :block='true') Фильтр...
                        popover(target='#numberFilterBtn', placement="bottom")
                            template(slot='popover')
                                .form-group
                                    input.form-control(
                                        type='text',
                                        v-model="params.search['number']",
                                        @keyup.enter="loadStat()",
                                        placeholder='Номер')
                                .form-group(v-if='zakgovruMode')
                                    input.form-control(
                                        type='text',
                                        v-model="params.search['type']",
                                        @keyup.enter="loadStat()",
                                        placeholder='Тип')
                                .form-group(v-if='zakgovruMode')
                                    select.form-control(v-model="params.search['prepay']", @change="loadStat()")
                                        option(:value="undefined") Любое обеспечение
                                        option(value="не требуется") Обеспечение не требуется
                                        option(value="\\d+") С обеспечением
                                .form-group(v-if='zakgovruMode')
                                    input.form-control(
                                        type='number',
                                        min='1',
                                        step='1',
                                        v-model.number="params.search['subItemsCount']",
                                        @keyup.enter="loadStat()",
                                        placeholder='Позиций')
                    +th_text_search('code_okrb')
                    +th_text_search('title')
                    th
                    +th_number_search('cost')
                    +th_text_search('org.title')
                    +th_date_search('job.nextCallDate')
                    +th_text_search('org.addr')
                    +th_text_search('org.fio')
                    +th_text_search('org.phone')
                    +th_text_search('org.fax')
                    +th_text_search('org.email')
                    +th_text_search('date')
                    +th_text_search('note')
            tbody
                tr(v-for="(row, rowIndex) in rows", :data-p-id="row.id")
                    td.actions-cell(:style='{backgroundColor: userCellBgColor(row)}')
                        btn(size='xs', v-tooltip.hover="row.job.user && row.job.user.fio", v-if='row.job && row.job.user')
                            span.glyphicon.glyphicon-user

                        div(v-if="clientMode")
                            btn(
                                size='xs',
                                v-tooltip.hover="'Забрать себе'"
                                @click='assignToUser(row, user.id)',
                                v-if="isItemFree(row)")
                                i.glyphicon.glyphicon-plus

                            dropdown(ref='dropdown')
                                btn.dropdown-toggle(size='xs')
                                    i.glyphicon.glyphicon-option-horizontal
                                template(slot='dropdown')
                                    li(v-for="m in managersExceptAssigned(row)")
                                        a(role='button', @click='assignToUser(row, m.id)',) {{m.fio}}

                            btn(
                                size='xs',
                                v-tooltip.hover="'Вернуть в общий список'"
                                @click='unassign(row)',
                                v-if="isItemMine(row) || (!isItemFree(row) && adminMode)")
                                i.glyphicon.glyphicon-minus

                            btn(
                                size='xs',
                                type='default',
                                v-tooltip.hover="'Удалить'"
                                @click='removeItem(row)',
                                v-if="isItemFree(row) && !row.deletedAt")
                                i.glyphicon.glyphicon-trash

                            btn(
                                size='xs',
                                type='default',
                                v-tooltip.hover="'Восстановить'"
                                @click='restoreItem(row)',
                                v-if="row.deletedAt")
                                i.glyphicon.glyphicon-repeat


                    td.pre(v-if='row.job') {{row.job.lastAssignDatetime | dateStr | spacesToNewlines}}
                    td(:class="groupClasses[rowIndex]")
                        span {{row.number}}
                        p(v-if='zakgovruMode')
                            sup.text-muted {{row.type}}
                        p(v-if='zakgovruMode && row.subItemsCount')
                            sup.text-muted позиций: {{row.subItemsCount}}
                        p(v-if='zakgovruMode && row.prepay')
                            sup.text-muted обеспечение: {{row.prepay}}
                        p(v-if='zakgovruMode')
                            sup.text-muted
                                a(target="_blank" :href="row.printFormLink", rel="noopener noreferrer") {{row.createdAt | dateStrSec}}
                        p(v-if='!zakgovruMode')
                            sup.text-muted {{row.createdAt | dateStrSec}}
                    td {{row.code_okrb}}
                    td.prewrap
                        span(v-if='!zakgovruMode') {{row.title}}
                        a(target="_blank" :href="row.link", rel="noopener noreferrer", v-if='zakgovruMode') {{row.title}}
                    td {{row.qty | num}}
                    td.nowrap {{row.cost | num}}
                    td(:class="groupClasses[rowIndex]")
                        span {{row.org.title}}
                        p(v-if='row.org.fields && row.org.fields.workersCount')
                            code.text-muted {{row.org.fields.workersCount}} чел.
                        .org-job-users(v-if='row.org.jobUsers && row.org.jobUsers.length')
                            span(v-for='jobUser in row.org.jobUsers', v-tooltip.hover="jobUser.fio", :style='{backgroundColor: jobUser.color}').label {{jobUser.fio | fioLetters}}
                    td.calls-cell
                        div
                            ul.list-group.job-call-info(v-if='row.job')
                                li.list-group-item.prewrap(
                                    v-if="row.job && row.job.comment"
                                    @dblclick='openNewCallModal(row)',
                                    v-linkified-html='row.job.comment')
                                li.list-group-item(v-if="!row.job.closed && isItemMine(row)")
                                    label Перезвонить:
                                    input.pull-right(
                                        style='width: 50%',
                                        type='date',
                                        :min='todayDate',
                                        :value="row.job.nextCallDate && row.job.nextCallDate.split('T')[0]"
                                        @input="row.job.nextCallDate = $event.target.value"
                                        @change='nextCallDateChanged(row)')
                                li.list-group-item.list-group-item-warning.prewrap(
                                    v-if='row.job.closed && row.job.closeComment',
                                    v-linkified-html='row.job.closeComment')
                            .text-center.calls-buttons(v-if="isItemMine(row)")
                                btn-group(size='sm')
                                    btn(
                                        type='default',
                                        @click='openNewCallModal(row)',
                                        v-tooltip.hover="'Звонок'",
                                        v-if='!row.job.closed')
                                        span.glyphicon.glyphicon-earphone
                                    btn(
                                        type='default',
                                        @click='markJobAsClosed(row)',
                                        v-tooltip.hover="'Завершить сделку'",
                                        v-if='!row.job.closed && row.job.comment')
                                        span.glyphicon.glyphicon-remove
                                    btn(
                                        type='default',
                                        @click='markJobAsOpened(row)',
                                        v-tooltip.hover="'Вернуть в работу'",
                                        v-if='row.job.closed')
                                        span.glyphicon.glyphicon-repeat

                    +td_editable('org.addr', 'prewrap')
                    +td_editable('org.fio', 'prewrap')
                    +td_editable('org.phone', 'pre')
                    +td_editable('org.fax', 'pre')
                    +td_editable('org.email', 'pre')

                    +td_editable('date', 'prewrap')
                    +td_editable('note', 'prewrap')

        iframe#downloadIFrame(width='1', height='1', frameborder=0, src='about:blank')
</template>

<script>
  import cssobj from 'cssobj';
  import ioClient from '../io.client';
  import debounce from 'lodash/debounce';
  import get from 'lodash/get';
  import set from 'lodash/set';
  import pick from 'lodash/pick';
  import JobForm from '../components/job.form';

  const {Purchase, User, Option, Job, ClientPurchase, ClientOrg} = ioClient.rest;

  const persistedFields = ['params', 'hiddenCols'];

  // move to separate module???
  const mainCSSObj = {};
  const cssobjResult = cssobj(mainCSSObj);

  const defaultSearch = {
    //'job.nextCallDate': {}
  };

  const APP_STATE_KEY = 'appStateV20';

  export default {

    props: ['owner', 'zakgovruMode'],

    components: {
      JobForm
    },

    data: () => ({
      todayDate: m().format('YYYY-MM-DD'),
      colChooserElements: [],
      colsDef: [
        {},
        {title: 'Взята в работу', hideable: false},
        {title: 'Номер', hideable: true, field: 'number'},
        {title: 'Код', hideable: true, field: 'code_okrb'},
        {title: 'Предмет', hideable: true, field: 'subject'},
        {title: 'Кол-во', hideable: true, field: 'qty'},
        {title: 'Цена', hideable: true, field: 'cost_str'},
        {title: 'Организация', hideable: true, field: 'org.title'},
        {title: 'Звонки', hideable: true},
        {title: 'Адрес', hideable: true, field: 'org.addr'},
        {title: 'ФИО', hideable: true, field: 'org.fio'},
        {title: 'Тел', hideable: true, field: 'org.phone'},
        {title: 'Факс', hideable: true, field: 'org.fax'},
        {title: 'Email', hideable: true, field: 'org.email'},
        {title: 'Проведение', hideable: true, field: 'date'},
        {title: 'Примечание', hideable: true, field: 'note'},
      ],
      hiddenCols: [15, 13, 12, 11, 10, 9, 14],
      user: ioClient.user,
      rows: [],
      stat: {
        foundCount: 0,
        totalCount: 0,
      },
      params: {
        search: defaultSearch,
        sort: {
          col: 'createdAt',
          dir: -1,
        },
        cursor: 0,
        count: 50,
      },
      loading: false,
      topFilter: '',
      managers: [],
      newCallModalData: null,
    }),

    created() {
      this.getLastState();
    },

    mounted() {
      this.loadManagers();
      this.loadStat();
      $(window).on('scroll', debounce(() => {
        if (window.scrollY + window.innerHeight + 5 >= document.body.offsetHeight) {
          this.loading || (this.rows.length >= this.stat.foundCount) || this.loadStat(true);
        }
      }, 500));
      $('a.sort-link').click(() => $('html, body').scrollTop(0));

      this.colChooserElements = [...this.$el.querySelectorAll('.not-close-item a')];

      mainCSSObj.table = this.colsDef.reduce((acc, def, i) => {
        acc[`th:nth-child(${i + 1}), td:nth-child(${i + 1})`] = {
          display: () => this.hiddenCols.includes(i) || (!['mine', 'mine-closed'].includes(this.owner) && i === 1) ? 'none' : 'table-cell'
        };
        return acc;
      }, {});
      cssobjResult.update();
    },

    beforeDestroy() {
      $(window).off('scroll');
      delete mainCSSObj.table;
      cssobjResult.update();
    },

    destroyed() {
    },

    methods: {
      getLastState() {
        const stateRaw = localStorage.getItem(this.getPersistingKey());
        if (stateRaw) {
          try {
            Object.assign(this, JSON.parse(stateRaw));
          } catch (e) {
          }
        }
      },
      persistState() {
        localStorage.setItem(this.getPersistingKey(), JSON.stringify(pick(this, persistedFields)));
      },
      toggleSort(field) {
        this.params = {
          ...this.params,
          sort: {
            col: field,
            dir: -1 * this.params.sort.dir,
          }
        };
        this.loadStat();
      },
      async loadManagers() {
        this.managers = await User.getClientUsers();
      },
      getFindParams() {
        const search = {...this.params.search};

        if (this.topFilter) {
          if (this.topFilter === 'del') {
            search['deletedAt'] = 1;
          } else {
            search['userId'] = this.topFilter;
          }
        }

        if (this.owner === 'free') {
          search['userId'] = null;
        } else if (this.owner === 'mine') {
          search['userId'] = this.user.id;
          search['job.closed'] = null;
        } else if (this.owner === 'mine-closed') {
          search['userId'] = this.user.id;
          search['job.closed'] = true;
        } else if (this.owner === 'plus') {
          search['tags'] = ['plus'];
        }

        return {
          search,
          select: null,
          options: {
            offset: 50 * this.params.cursor || 0,
            limit: this.params.count || 50,
            order: [
              this.zakgovruMode ? ['createdAt', 'DESC'] : [this.params.sort.col || 'title', this.params.sort.dir > 0 ? 'asc' : 'desc']
            ],
          },
        };
      },
      async loadStat(more = false) {
        this.loading = true;
        if (more) {
          this.params.cursor++;
        } else {
          this.params.cursor = 0;
        }
        const p = this.getFindParams();
        const {rows, count, totalCount} = await ClientPurchase.findExt({where: p.search, ...p.options});

        if (more) {
          this.rows.push(...rows);
        } else {
          this.rows = rows;
        }
        this.stat = {...this.stat, foundCount: count, totalCount};
        this.loading = false;
        this.persistState();
      },
      async updateFieldContent(item, field, content) {
        const currValue = get(item, field);
        const changed = currValue !== content;
        if (!changed) return;
        if (!content) return this.revertFieldContent(item, field);

        set(item, field, content);

        const changeOrg = field.includes('org.');

        if (changeOrg) {
          const orgField = field.split('.').pop();
          this.rows.filter(r => r.orgId === item.orgId).forEach(r => set(r, field, content));
          await ClientOrg.update({[orgField]: content}, {where: {id: item.orgId}});
        } else {
          await ClientPurchase.update({[field]: content}, {where: {id: item.id}});
        }

      },
      async revertFieldContent(item, field) {
        const changeOrg = field.includes('org.');

        if (changeOrg) {
          const orgField = field.split('.').pop();
          set(item, field, item.org.origData[orgField]);
          this.rows.filter(r => r.orgId === item.orgId).forEach(r => set(r, field, item.org.origData[orgField]));
          await ClientOrg.update({[orgField]: null}, {where: {id: item.orgId}});
        } else {
          set(item, field, item.origData[field]);
          await ClientPurchase.update({[field]: null}, {where: {id: item.id}});
        }
      },
      async assignToUser(item, userId) {
        item.job = await Job.assignToUser(item.id, userId);
        if (this.owner !== 'all') {
          this.rows = [...this.rows.filter(i => i.id !== item.id)];
        } else {
          item.job.user = this.managers.find(u => u.id === userId);
        }
      },
      async unassign(item) {
        await Job.unassign(item.id);
        item.job = {};
        if (['mine', 'mine-closed'].includes(this.owner)) {
          this.rows = [...this.rows.filter(i => i.id !== item.id)];
        }
      },
      isItemFree(item) {
        return !get(item, 'job.user');
      },
      isItemMine(item) {
        return get(item, 'job.user.id') === this.user.id;
      },
      async removeItem(item) {
        await ClientPurchase.destroy({where: {id: item.id}});
        this.rows = [...this.rows.filter(i => i.id !== item.id)];
      },
      async restoreItem(item) {
        await ClientPurchase.restoreDeleted(item.id);
        this.rows = [...this.rows.filter(i => i.id !== item.id)];
      },
      userCellBgColor(item) {
        return get(item, 'job.user.color');
      },
      async openNewCallModal(item) {
        this.newCallModalData = item;
      },
      async updateJob(data) {
        await Job.update(
          {comment: data.comment, nextCallDate: data.nextCallDate},
          {where: {id: data.jobId}}
        );
        this.newCallModalData = null;
      },
      async nextCallDateChanged({job: {id, nextCallDate}}) {
        await Job.update({nextCallDate}, {where: {id}});
      },
      async markJobAsClosed(item) {
        const closeComment = await this.$prompt({
          title: 'Завершить сделку',
          content: 'Причина',
          okText: 'Ok',
          cancelText: 'Отмена',
          validator: value => {
            if (!value.trim()) return 'Нужна веская причина :)';
            return null;
          }
        }).catch(() => false);
        if (!closeComment) return;
        await Job.update({closed: true, closeComment}, {where: {id: item.job.id}});
        this.rows = [...this.rows.filter(i => i.id !== item.id)];
        q
      },
      async markJobAsOpened(item) {
        const confirmResult = await this.$confirm({
          title: 'Вернуть в работу',
          content: 'Вы уверены?',
          okText: 'Да',
          cancelText: 'Отмена',
        }).catch(() => false);
        if (!confirmResult) return;
        await Job.update({closed: null, closeComment: null}, {where: {id: item.job.id}});
        this.rows = [...this.rows.filter(i => i.id !== item.id)];
      },
      async downloadCSV() {
        const params = this.getFindParams();
        params.select = this.colsDef
          .filter((v, i) => !this.hiddenCols.includes(i))
          .reduce((acc, v) => {
            if (v.field) acc[v.field] = 1;
            return acc;
          }, {});
        await Option.updateOne('last_search_' + this.user.id, params);
        $('#downloadIFrame').attr('src', '/download/csv/' + this.user.id);
      },
      async downloadForFaxTxt() {
        const params = this.getFindParams();
        await Option.updateOne('last_search_' + this.user.id, params);
        $('#downloadIFrame').attr('src', '/download/fax-txt/' + this.user.id);
      },
      resetAllFilters() {
        this.params = {
          ...this.params,
          search: defaultSearch,
          sort: {
            col: 'id',
            dir: -1,
          }
        };

        this.loadStat();
      },
      updateColVisibility(toggleColIndex) {
        this.hiddenCols = this.hiddenCols.includes(toggleColIndex) ?
          this.hiddenCols.filter(cIndex => cIndex !== toggleColIndex) : [...this.hiddenCols, toggleColIndex];
        cssobjResult.update();
        this.persistState();
      },
      modalOpened() {
        $('.modal-dialog').draggable({
          handle: '.modal-header'
        });
      },
      getPersistingKey() {
        return [this.zakgovruMode ? 'zakgovru' : '', APP_STATE_KEY, document.location.pathname].join(':')
      },
      managersExceptAssigned(item) {
        const jobUserId = get(item, 'job.user.id');
        return this.managers.filter(u => u.id !== jobUserId);
      },
    },

    computed: {
      hideableCols() {
        return this.colsDef
          .map((def, index) => ({...def, index, hidden: this.hiddenCols.includes(index)}))
          .filter(({hideable}) => hideable);
      },
      groupClasses() {
        if (!this.zakgovruMode) return [];
        const groupProp = this.zakgovruMode ? 'number' : 'orgId';
        return this.rows.map((r, i) => {
          const prevZakNum = get(i > 0 ? this.rows[i - 1] : {}, groupProp);
          const zakNum = get(r, groupProp);
          const nextZakNum = get(i < (this.rows.length - 1) ? this.rows[i + 1] : {}, groupProp);

          const classes = [];

          if (prevZakNum && (prevZakNum === zakNum)) {
            classes.push('border-top-none');
          }

          if (nextZakNum && (nextZakNum === zakNum)) {
            classes.push('border-bottom-none');
          }

          return classes;
        });
      },
      clientMode() {
        return this.user.clientId !== null;
      },
      adminMode() {
        return ['root', 'admin'].includes(this.user.role);
      },
      newCallModalDataOpened: {
        get() {
          return this.newCallModalData !== null;
        },
        set(v) {
          if (!v) this.newCallModalData = null;
        }
      }
    },

  };
</script>
